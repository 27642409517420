<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  ADDWINE_ADMIN_ROUTES,
  components: {
    Menu,
  },
  data() {
    return {
      menu: [
        {
          title: 'SEO-шаблоны',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.SEO_TEMPLATES.INDEX,
        },
        {
          title: 'SEO-тексты',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.SEO_TEXTS.INDEX,
        },
        { title: 'FAQ', link: ADDWINE_ADMIN_ROUTES.CONTENT.FAQS.INDEX },
        { title: 'SEO-FAQ', link: ADDWINE_ADMIN_ROUTES.CONTENT.SEO_FAQS.INDEX },
        { title: 'Страницы', link: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.INDEX },
        {
          title: 'Вакансии',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.VACANCIES.INDEX,
        },
        { title: 'Отзывы', link: ADDWINE_ADMIN_ROUTES.CONTENT.REVIEWS.INDEX },
        {
          title: 'Команда проекта',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.TEAM_MEMBERS.INDEX,
        },
        { title: 'Клиенты', link: ADDWINE_ADMIN_ROUTES.CONTENT.CLIENTS.INDEX },
        {
          title: 'Партнёры',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PARTNERS.INDEX,
        },
        {
          title: 'Документы',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.DOCUMENTS.INDEX,
        },
        { title: 'SEO', link: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.INDEX },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
